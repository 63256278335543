const stations = [
  {
    name: 'WLIF Radio',
    location: 'Baltimore, MD',
    freq: 'FM 101.9',
    link: 'https://www.audacy.com/todays1019',
    // dates: [
    //   'Nov. 19th 7:00pm',
    //   'Nov. 26th 7:00pm',
    //   'Dec. 3rd 7:00pm',
    //   'Dec. 10th 7:00pm',
    //   'Dec. 17th 7:00pm',
    // ],
    dates: ['Coming Soon'],
  },
  {
    name: 'Radio Ocean City',
    location: 'Ocean City, MD',
    freq: 'www.radiooceancity.com',
    link: 'https://www.radiooceancity.com/',
    // dates: ['All December Long 6:00am-7:00am & 3:00pm-4:00pm'],
    dates: ['Coming Soon'],
  },
  {
    name: 'WCEI',
    location: 'Easton, MD',
    freq: 'FM 96.7',
    link: 'https://www.forevermidshore.com/',
    // dates: ['Dec. 24th 7:00pm - 12:00am', 'Dec. 25th 9:00am - 2:00pm'],
    dates: ['Coming Soon'],
  },
  {
    name: 'WINX-FM',
    location: 'St. Michaels, MD',
    freq: 'FM 94.3',
    link: 'https://www.forevermidshore.com/',
    // dates: ['Dec. 25th 5:00am - 10:00am'],
    dates: ['Coming Soon'],
  },
  {
    name: 'WCBC',
    location: 'Cumberland, MD',
    freq: 'AM 1270',
    link: 'https://www.wcbcradio.com/',
    // dates: ['Dec. 25th 7:00am - 10:00pm', 'Jan. 1st 6:00am - 12:00pm'],
    dates: ['Coming Soon'],
  },
  {
    name: 'WHGM',
    location: 'Harve De Grace, MD',
    freq: 'FM 100.5',
    link: 'https://whgmgold.com/',
    // dates: [
    //   'Dec. 8th - 7:00pm',
    //   'Dec. 15th - 7:00pm',
    //   'Dec. 22nd - 7:00pm',
    //   'Dec. 23rd - 7:00pm',
    //   'Dec. 24th - 12:00pm',
    // ],
    dates: ['Coming Soon'],
  },
  {
    name: 'WBEY Bay Country',
    location: 'Pokomoke City, MD',
    freq: 'FM 97.9',
    link: 'https://baycountry979.com/',
    // dates: ['Dec. 24th 12:00pm', 'Dec. 25th 5:00pm'],
    dates: ['Coming Soon'],
  },
  {
    name: 'WCTG',
    location: 'Pokomoke City/Salisbury, MD',
    freq: 'FM 96.6 & 101.5',
    link: 'https://ctgvariety.com/',
    // dates: ['Dec. 25th 7:00am'],
    dates: ['Coming Soon']
  },
];

module.exports = stations;
